<div class="border border-palette-gray-200 h-12 w-full flex items-center justify-between px-4">
  <i id="chat-back-btn" class="sctr-icon-chevron-left text-xl" (click)="goBack()"></i>
  <div class="font-semibold text-base">Chat</div>
  <i class="sctr-icon-search-md text-xl text-palette-base-white"></i>
</div>

<lib-chat-popup *ngIf="!isLoading; else loading_data" [isMobileWebView]="true" [userInfo]="userInfo" [baseUrl]="baseUrl"
  [(isDetailView)]="isDetailView" [module]="module" [isEnableChatBot]="isEnableChatBot"
  [isDisableCloseDialogFromOutside]="true"></lib-chat-popup>

<ng-template #loading_data>
  <div class="flex flex-col p-4" *ngFor="let _ of [].constructor(3)">
    <div class="flex">
      <div class="mr-2">
        <p-skeleton size="2rem" shape="circle"></p-skeleton>
      </div>
      <div class="flex items-center">
        <div class="flex flex-col">
          <p-skeleton width="8rem" height="1rem" class="mb-1"></p-skeleton>
          <p-skeleton width="4rem" height="0.75rem"></p-skeleton>
        </div>
      </div>
    </div>
  </div>
</ng-template>