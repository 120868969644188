import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { UserService } from './service/user.service';
import { ChatService } from '@soctrip-common/chat';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'socchat-app';
  baseUrl = environment.baseURL;
  userInfo: any;
  isDetailView = false;
  module: string;
  isEnableChatBot = false;
  isLoading = true;

  constructor(
    private userService: UserService,
    private chatService: ChatService,
  ) {
    Object.keys(localStorage).forEach(key => {
      localStorage.removeItem(key);
    });
    window.addEventListener('message', event => {
      if (event) {
        const message = event.data;
        switch (message.type) {
          case 'initial':
            localStorage.setItem('access_token', JSON.stringify({ accessToken: message.data.token }));
            this.userService.getUserInfo(message.data.userId).subscribe((res) => {
              localStorage.setItem('user_profile', JSON.stringify(res.data));
              this.userInfo = res.data;
              this.isLoading = false;
            });
            this.isEnableChatBot = message.data.isEnableChatBot || false;
            this.module = message.data.module;
            if (message.data.agencyId) {
              const contactInfo = {
                agencyId: message.data.agencyId,
                meta_data: message.data.meta_data,
              };
              this.chatService.chatAgencySupportSubject.next(contactInfo);
            };
            break;
          default:
            break;
        }
      }
    });
  }

  goBack() {
    if (this.isDetailView) {
      this.isDetailView = false;
      return;
    }
    window.postMessage('goBack', '*');
  }
}
